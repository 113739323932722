import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import QubGroupGrid from 'qubCommon/Grids/QubGroupGrid';
import { wijmoDateFormat, wijmoStyleGrid } from 'qubCommon/wijmoHelpers/columnMethods';
import 'assets/css/explorer.css';

const BringValueGrid = (props) => {
    const { columns, groupColumns, data, gridInitialized, showRowHeader, showColHeaderGroup, cellCssMapping, ...rest } = props;
    const [gridColumns, setGridColumns] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const helpCols = [];
        columns.forEach((column) =>
            helpCols.push({
                ...column,
                width: column?.width ?? '*'
            })
        );
        setGridColumns(helpCols);
    }, [columns]);

    const gridLoadedRows = (flex) => {
        const columnStyles = [];
        groupColumns?.forEach((group) => {
            group?.children.forEach((child) => {
                const columnStyle = {};
                columnStyle.binding = child?.fldName;
                columnStyle.colCss = child?.colCss;
                columnStyles.push(columnStyle);
            });
        });
        const cellStyles = {};
        cellCssMapping?.forEach((object) => {
            cellStyles[object?.fldName] = object?.fldNameCss;
        });
        flex.itemFormatter = (panel, r, c, cell) => {
            wijmoStyleGrid(panel, r, c, cell, columnStyles, cellStyles, flex);
            flex.columns.forEach((col) => {
                if (col?.format === 'date') {
                    wijmoDateFormat(panel, r, c, cell, col.binding, false, { format: 'dd/MM/yyyy' });
                }
                if (col?.format === 'datetime') {
                    wijmoDateFormat(panel, r, c, cell, col.binding, true, { format: 'dd/MM/yyyy' });
                }
            });
        };
        flex.focus();
    };

    return (
        <Box style={{ height: '50vh', width: '100%' }}>
            {showColHeaderGroup ? (
                <QubGroupGrid
                    style={{ height: '99%', minHeight: 200 }}
                    initialized={gridInitialized}
                    gridData={data}
                    loadedRows={gridLoadedRows}
                    gridColumns={groupColumns}
                    headersVisibility={showRowHeader ? 'All' : 'Column'}
                />
            ) : (
                <FlexGrid
                    style={{ height: '99%' }}
                    id="explGrid"
                    initialized={gridInitialized}
                    loadedRows={gridLoadedRows}
                    itemsSource={data}
                    autoSizeMode={1}
                    isReadOnly
                    alternatingRowStep={0}
                    headersVisibility={showRowHeader ? 'All' : 'Column'}
                    {...rest}
                    className="explorer-grid"
                >
                    <FlexGridFilter />
                    {gridColumns.length > 0 &&
                        gridColumns.map((c, index) => (
                            <FlexGridColumn
                                key={index}
                                visible={c?.visible}
                                binding={c.binding}
                                format={c?.format}
                                width={c?.width}
                                header={t(c.header) ?? t(c.binding)}
                                className="explorer-column"
                            />
                        ))}
                </FlexGrid>
            )}
        </Box>
    );
};

export default BringValueGrid;
