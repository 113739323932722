/* eslint-disable import/no-cycle */
import axiosInstance from "apis/axiosInstance";

export const configurationApi = {
    post: async (path, params, cancelToken) =>
        axiosInstance
            .post(`/configuration${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err.error ? err : err?.response;
            }),
    get: async (path, params, cancelToken) =>
        axiosInstance
            .get(
                `/configuration${path}`,
                { params },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response;
            })
};

//Checklist
export const getLKEvents = async () => configurationApi.get('/getEvents');
export const getLKSeason = async () => configurationApi.get('/getSeason');
export const getApplicationTypeProductAges = async () => configurationApi.get('/getApplicationTypeProductAges');
export const getApplicationTypeProductServices = async () => configurationApi.get('/getApplicationTypeProductServices');
export const getFileTypes = async () => configurationApi.get('/getFileTypes');
export const getApplicationType = async () => configurationApi.get('/getApplicationType');
export const getOptionalAnswers = async () => configurationApi.get('/getOptionalAnswer');
export const getQuestion = async () => configurationApi.get('/getQuestion');
export const postSeason = async (params) => configurationApi.post('/postSeason', params);
export const postEvents = async (params) => configurationApi.post('/postEvents', params);
export const postApplicationType = async (params) => configurationApi.post('/postApplicationType', params);
export const postApplicationTypeProductAges = async (params) => configurationApi.post('/postApplicationTypeProductAges', params);
export const postApplicationTypeProductServices = async (params) => configurationApi.post('/postApplicationTypeProductServices', params);
export const postFileTypes = async (params) => configurationApi.post('/postFileTypes', params);

//Event Categories
export const getEventCategories = async () => configurationApi.get('/getEventCategories');
export const postEventCategories = async (params) => configurationApi.post('/postEventCategories', params);