import { Button, Grid, Typography } from '@mui/material';
import { getData } from 'apis/controllers/apiEntities';
import { Checkbox, TextInput } from 'qubCommon/Inputs';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';

const ResultsEventJsonForm = () => {
    const form = useForm();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [eventInfo, setEventInfo] = useState(null);
    const gridRef = useRef();

    const fetchEventData = async () => {
        const res = await getData({ dataKey: 'Admin-Config-Result-Event-Manage', parameters: { eventId: id } });
        console.log(res);
        setData(res?.data);
        setEventInfo(res?.message);
    };

    useEffect(() => {
        fetchEventData();
    }, []);

    return (
        <Grid container spacing={2} sx={{ padding: 4 }}>
            <Grid item xs={12}>
                <Typography variant="h3">
                    {eventInfo?.Id} - {eventInfo?.Name} - {eventInfo?.Abbr}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FlexGrid
                    itemsSource={data}
                    initialized={(flex) => {
                        gridRef.current = flex;
                    }}
                    className="explorer-grid"
                    visible
                    allowAddNew
                >
                    <FlexGridColumn binding="fldName" header="field Name" visible />
                    <FlexGridColumn binding="fldTitle" header="field Title" visible />
                    <FlexGridColumn binding="fldTitleb423" header="field Title BEFORE (2023)" visible />
                    <FlexGridColumn binding="fldHidden" header="field Hidden" visible dataType="Boolean" />
                    <FlexGridColumn binding="fldHiddenb423" header="field Hidden BEFORE(2023)" visible dataType="Boolean" />
                </FlexGrid>
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={async () => {
                        const res = await getData({
                            datakey: 'Adming-Config-Result-Event-Save',
                            parameters: { eventId: id, json: gridRef.current.collectionView.items }
                        });
                        console.log(res);
                    }}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
};
export default ResultsEventJsonForm;
