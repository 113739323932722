import React, { useEffect, useState } from 'react';
import { IconArrowNarrowRight, IconPlus, IconSearch } from '@tabler/icons';
import { BringValueInput } from 'qubCommon/Inputs';
import MainCard from 'ui-component/cards/MainCard';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, ListItem, Button, Box } from '@mui/material';
import { format } from 'date-fns';
import { person, backoffice } from 'apis';
import { isNil } from 'lodash';
import usePerson from '../Users/hooks/usePerson';
import PersonInfo from 'ui-component/person/PersonInfo';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import useDateTranslatorHelper from 'utils/date-translate';

const PersonFunction = ({ f, onClick, personId, hasUser }) => {
    const [hover, setHover] = useState(false);
    // const dateFrom = format(new Date(f?.dateFrom), 'MMM yyyy');
    const dateFrom = useDateTranslatorHelper(new Date(f?.dateFrom));
    const dateTo = useDateTranslatorHelper(f?.dateTo ? new Date(f?.dateTo) : null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    // const optionsQuery = useQuery('options', async () => {
    //     try {
    //         const returnFunctions = await backoffice.getLookups({ dataKey: 'AvailableFunctions' });
    //         const functions = returnFunctions.filter((f) => f?.isActive === true && f?.EntityTypeId > 200);
    //         return {
    //             functions
    //         };
    //     } catch (error) {
    //         console.error('error', error);
    //     }
    //     return {};
    // });

    // useEffect(() => {}, [optionsQuery.data]);

    return (
        <ListItem key={f.personFunctionId} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack>
                <Typography variant="h4">{f?.function}</Typography>
                <Typography variant="body1">{f?.organization}</Typography>
                {f?.lblRegNumber && f?.regNumber ? (
                    <Typography variant="body" style={{ color: '#788693', fontSize: 'small' }}>
                        {`${f?.lblRegNumber}: ${f?.regNumber} `}
                    </Typography>
                ) : null}
                <Typography variant="subtitle2">{`${dateFrom} - ${dateTo ?? t('Present')}`}</Typography>
            </Stack>
            <Box>
                <Button onClick={() => onClick(f)} sx={{ margin: 1 }} variant="outlined">
                    {t('View')}
                </Button>
                {!hasUser && f?.functionId > 200 && f?.isActive && (
                    <Button
                        variant="outlined"
                        onClick={() => navigate(`/users/create/${personId}/${f?.personFunctionId}`)}
                        sx={{ margin: 1 }}
                    >
                        {t('CreateNewUser')}
                    </Button>
                )}
            </Box>
        </ListItem>
    );
};
//

PersonFunction.propTypes = {
    f: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};

export const PersonFunctionsPretty = ({ personId, showTitle = true, hasUser, functions }) => {
    // const [functions, setFunctions] = useState(null);

    const navigate = useNavigate();
    const { t } = useTranslation();

    // useEffect(() => {
    //     const getFunctions = async () => {
    //         const result = await person.getFunctions({ personId });
    //         setFunctions(result);
    //     };
    //     if (!isNil(personId)) getFunctions();
    //     else setFunctions(null);
    // }, [personId]);

    const onClick = (func) => {
        if (func.isOfficial) {
            navigate(`/officials/manage/${func.personFunctionId}/details`);
        } else if (func.dataKey === '@Athlete') {
            navigate(`/athletes/manage/${func.personFunctionId}/details`);
        }
    };

    return functions ? (
        <MainCard
            style={{ marginTop: 15, maxHeight: '100%', overflowY: 'auto' }}
            stickyTitle
            title={showTitle ? t('Functions') : null}
            border={false}
        >
            {functions.map((f) => (
                <PersonFunction key={f.personFunctionId} f={f} onClick={onClick} personId={personId} hasUser={hasUser} />
            ))}
        </MainCard>
    ) : null;
};

const Search = () => {
    const form = useForm();
    const { t } = useTranslation();
    const [personId, setPersonId] = useState(null);
    const [functions, setFunctions] = useState(null);
    const [hasAccess, setHasAccess] = useState(false);
    const personQuery = usePerson(personId);

    const handleSelect = (item) => {
        const selectedPersonId = item?.Id;
        setPersonId(selectedPersonId);
    };

    useEffect(() => {
        const getFunctions = async () => {
            const result = await person.getFunctions({ personId });
            setFunctions(result);
            console.log('result', result);
            if (result.length > 0 && result.filter((f) => f.statusId == 300).length > 0) {
                setHasAccess(true);
            } else {
                setHasAccess(false);
            }
        };
        if (!isNil(personId)) getFunctions();
        else {
            setFunctions(null);
            setHasAccess(false);
        }
    }, [personId]);

    return (
        <Stack style={{ height: 'calc(100vh - 130px)' }}>
            <FormProvider {...form}>
                <BringValueInput
                    id="Id"
                    name="Id"
                    placeholder={t('Search')}
                    dataKey="People-BV"
                    onNotFound={() => form.setValue('Id', null)}
                    onSelect={handleSelect}
                />
            </FormProvider>
            {personQuery.isSuccess && personQuery.data.id && (
                <MainCard style={{ marginTop: 15, paddingBottom: 24 }} title={false} border={false}>
                    <PersonInfo person={personQuery.data} hasAccess={hasAccess} />
                </MainCard>
            )}
            <PersonFunctionsPretty personId={personId} hasUser={personQuery?.data?.hasUser} functions={functions} />
        </Stack>
    );
};

export default Search;
